'use client';

import React, { useEffect, useRef, useState, useMemo, Suspense } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';

// Add interface for props
interface DiscoBallProps {
    quality?: 'low' | 'medium' | 'high';
    size?: 'small' | 'medium' | 'large';
    renderMode?: '3d' | 'image' | 'auto';
    disableAnimation?: boolean;
    imageProps?: {
        className?: string;
        priority?: boolean;
    };
    alt?: string;
}

// Quality presets
const QUALITY_SETTINGS = {
    low: {
        ballDetail: 4,
        mirrorSize: 0.08,
        pixelRatio: 1,
    },
    medium: {
        ballDetail: 6,
        mirrorSize: 0.06,
        pixelRatio: 1.5,
    },
    high: {
        ballDetail: 9,
        mirrorSize: 0.05,
        pixelRatio: typeof window !== 'undefined' ? window.devicePixelRatio : 1,
    },
} as const;

// Size presets
const SIZE_SETTINGS = {
    small: {
        cameraFov: 45,
        cameraZ: 2.5,
        rotationSpeed: 0.05,
    },
    medium: {
        cameraFov: 50,
        cameraZ: 2,
        rotationSpeed: 0.075,
    },
    large: {
        cameraFov: 55,
        cameraZ: 1.8,
        rotationSpeed: 0.1,
    },
} as const;

// Static image disco ball component
const StaticDiscoBall: React.FC<Pick<DiscoBallProps, 'size' | 'imageProps' | 'alt'>> = ({
    size = 'medium',
    imageProps = {},
    alt = 'Disco Ball'
}) => {
    // Adjust size class based on the size prop
    const sizeClass = {
        small: 'max-w-[200px] max-h-[200px]',
        medium: 'max-w-[300px] max-h-[300px]',
        large: 'max-w-[400px] max-h-[400px]',
    }[size];

    const dimensions = {
        small: { width: 40, height: 40 },
        medium: { width: 50, height: 50 },
        large: { width: 180, height: 180 },
    }[size];

    return (
        <div className="flex items-center justify-center w-full h-full">
            <img
                src="/img/discoball_nobackground.png"
                alt={alt}
                width={dimensions.width}
                height={dimensions.height}
                className={`${sizeClass} ${imageProps.className || ''}`}
            />
        </div>
    );
};

// Check if the browser can support WebGL
const canUseWebGL = () => {
    if (typeof window === 'undefined') return false;

    try {
        const canvas = document.createElement('canvas');
        return !!(window.WebGLRenderingContext &&
            (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
    } catch (e) {
        return false;
    }
};

const ThreeJSDiscoBall: React.FC<DiscoBallProps> = ({
    quality = 'medium',
    size = 'medium',
    disableAnimation = false
}) => {
    const mountRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(true);
    const [texture, setTexture] = useState<any | null>(null);
    const [webglError, setWebglError] = useState<boolean>(false);
    const [threeModules, setThreeModules] = useState<any | null>(null);

    // Only load Three.js modules on the client side when the component mounts
    useEffect(() => {
        const loadThreeModules = async () => {
            try {
                // Dynamic imports for Three.js modules with specific imports for tree-shaking
                const [
                    { TextureLoader, WebGLRenderer, Clock, Scene, PerspectiveCamera, Object3D, Group, MeshMatcapMaterial, MeshBasicMaterial, PlaneGeometry, IcosahedronGeometry, InstancedMesh },
                    { OrbitControls },
                    { mergeVertices }
                ] = await Promise.all([
                    import('three').then(three => ({
                        TextureLoader: three.TextureLoader,
                        WebGLRenderer: three.WebGLRenderer,
                        Clock: three.Clock,
                        Scene: three.Scene,
                        PerspectiveCamera: three.PerspectiveCamera,
                        Object3D: three.Object3D,
                        Group: three.Group,
                        MeshMatcapMaterial: three.MeshMatcapMaterial,
                        MeshBasicMaterial: three.MeshBasicMaterial,
                        PlaneGeometry: three.PlaneGeometry,
                        IcosahedronGeometry: three.IcosahedronGeometry,
                        InstancedMesh: three.InstancedMesh
                    })),
                    import('three/examples/jsm/controls/OrbitControls'),
                    import('three/examples/jsm/utils/BufferGeometryUtils').then(utils => ({
                        mergeVertices: utils.mergeVertices
                    }))
                ]);

                setThreeModules({
                    TextureLoader, WebGLRenderer, Clock, Scene, PerspectiveCamera, Object3D, Group,
                    MeshMatcapMaterial, MeshBasicMaterial, PlaneGeometry, IcosahedronGeometry, InstancedMesh,
                    OrbitControls, mergeVertices
                });

                // Load texture once we have the TextureLoader
                const textureLoader = new TextureLoader();
                textureLoader.load(
                    'https://assets.codepen.io/959327/matcap-crystal.png',
                    (loadedTexture) => {
                        setTexture(loadedTexture);
                        setLoading(false);
                    },
                    undefined,
                    (error) => {
                        console.error('An error occurred while loading the texture:', error);
                        setLoading(false);
                    }
                );
            } catch (error) {
                console.error('Error loading Three.js modules:', error);
                setWebglError(true);
                setLoading(false);
            }
        };

        loadThreeModules();
    }, []);

    // Set up Three.js scene once modules and texture are loaded
    useEffect(() => {
        if (!mountRef.current || !texture || !threeModules) return;

        const {
            WebGLRenderer, Clock, Scene, PerspectiveCamera, Object3D, Group,
            MeshMatcapMaterial, MeshBasicMaterial, PlaneGeometry, IcosahedronGeometry, InstancedMesh,
            OrbitControls, mergeVertices
        } = threeModules;

        let renderer, clock, scene, camera, controls;
        let animationFrameId;

        const init = () => {
            try {
                renderer = new WebGLRenderer({
                    alpha: true,
                    antialias: true,
                });
            } catch (error) {
                console.error('Error creating WebGL context:', error);
                setWebglError(true);
                setLoading(false);
                return;
            }
            renderer.setPixelRatio(QUALITY_SETTINGS[quality].pixelRatio);
            renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
            mountRef.current.appendChild(renderer.domElement);

            clock = new Clock();

            scene = new Scene();

            camera = new PerspectiveCamera(
                SIZE_SETTINGS[size].cameraFov,
                mountRef.current.clientWidth / mountRef.current.clientHeight,
                1,
                10
            );
            camera.position.z = SIZE_SETTINGS[size].cameraZ;
            scene.add(camera);

            controls = new OrbitControls(camera, mountRef.current);
            controls.minDistance = 2;
            controls.maxDistance = 5;
            controls.autoRotate = !disableAnimation;
            controls.autoRotateSpeed = 6;
            controls.enableZoom = false;
            controls.enableDamping = true;

            scene.add(createDiscoBall());
            animate();
        };

        const createDiscoBall = () => {
            const dummy = new Object3D();

            const mirrorMaterial = new MeshMatcapMaterial({
                matcap: texture,
            });

            const geometryOriginal = new IcosahedronGeometry(0.5, QUALITY_SETTINGS[quality].ballDetail);
            geometryOriginal.deleteAttribute('normal');
            geometryOriginal.deleteAttribute('uv');
            const mergedGeometry = mergeVertices(geometryOriginal);
            mergedGeometry.computeVertexNormals();

            const mirrorGeometry = new PlaneGeometry(
                QUALITY_SETTINGS[quality].mirrorSize,
                QUALITY_SETTINGS[quality].mirrorSize
            );
            const instancedMirrorMesh = new InstancedMesh(
                mirrorGeometry,
                mirrorMaterial,
                mergedGeometry.attributes.position.count
            );

            const positions = mergedGeometry.attributes.position.array;
            const normals = mergedGeometry.attributes.normal.array;
            for (let i = 0; i < positions.length; i += 3) {
                dummy.position.set(positions[i], positions[i + 1], positions[i + 2]);
                dummy.lookAt(positions[i] + normals[i], positions[i + 1] + normals[i + 1], positions[i + 2] + normals[i + 2]);
                dummy.updateMatrix();
                instancedMirrorMesh.setMatrixAt(i / 3, dummy.matrix);
            }

            const obj = new Group();
            const innerGeometry = mergedGeometry.clone();
            const ballInnerMaterial = new MeshBasicMaterial({ color: 0x222222 });
            const innerMesh = new InstancedMesh(innerGeometry, ballInnerMaterial, 1);
            innerMesh.setMatrixAt(0, new Object3D().matrix);
            innerMesh.count = 1;
            obj.add(innerMesh, instancedMirrorMesh);

            return obj;
        };

        const animate = () => {
            animationFrameId = requestAnimationFrame(animate);
            controls.update();

            if (!disableAnimation) {
                const delta = SIZE_SETTINGS[size].rotationSpeed * clock.getDelta();
                scene.children[0].rotation.x += delta;
                scene.children[0].rotation.z += delta;
            }

            renderer.render(scene, camera);
        };

        init();

        const handleResize = () => {
            if (mountRef.current) {
                camera.aspect = mountRef.current.clientWidth / mountRef.current.clientHeight;
                camera.updateProjectionMatrix();
                renderer.setSize(mountRef.current.clientWidth, mountRef.current.clientHeight);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            cancelAnimationFrame(animationFrameId);
            if (mountRef.current && renderer) {
                mountRef.current.removeChild(renderer.domElement);
            }
            if (renderer) {
                renderer.dispose();
            }
        };
    }, [texture, threeModules, quality, size, disableAnimation]);

    return (
        <div className="relative w-full aspect-square">
            {loading ? (
                <StaticDiscoBall size={size} alt="Disco Ball" />
            ) : webglError ? (
                <StaticDiscoBall size={size} alt="Disco Ball" />
            ) : (
                <div ref={mountRef} className="w-full h-full" />
            )}
        </div>
    );
};

const DiscoBall: React.FC<DiscoBallProps> = ({
    quality = 'medium',
    size = 'medium',
    renderMode = 'auto',
    disableAnimation = false,
    imageProps = {},
    alt = 'Disco Ball'
}) => {
    // If renderMode is 'image', always use the image
    if (renderMode === 'image') {
        return <StaticDiscoBall size={size} imageProps={imageProps} alt={alt} />;
    }

    // Only check for WebGL support in auto mode
    const [use3D, setUse3D] = useState(renderMode === '3d');

    useEffect(() => {
        if (renderMode === 'auto') {
            // Only check WebGL support, not device type or motion preferences
            setUse3D(canUseWebGL());
        } else {
            setUse3D(renderMode === '3d');
        }
    }, [renderMode]);

    // Use static image if WebGL is not supported
    if (!use3D) {
        return <StaticDiscoBall size={size} imageProps={imageProps} alt={alt} />;
    }

    // Otherwise use 3D version
    return (
        <ThreeJSDiscoBall
            quality={quality}
            size={size}
            disableAnimation={disableAnimation}
        />
    );
};

// Use dynamic import with proper loading fallback
export default dynamic(() => Promise.resolve(DiscoBall), {
    ssr: false,
    loading: () => <StaticDiscoBall size="large" imageProps={{ priority: true }} alt="Disco Ball" />,
});

// Create a wrapper component to properly handle loading state with size props
export const DiscoBallWithConsistentLoading: React.FC<DiscoBallProps> = (props) => {
    const { size = 'medium', alt = 'Disco Ball' } = props;
    const [isLoading, setIsLoading] = useState(true);
    const DynamicDiscoBall = dynamic(() => Promise.resolve(DiscoBall), {
        ssr: false,
        loading: () => <StaticDiscoBall size={size} imageProps={{ priority: true }} alt={alt} />,
    });

    useEffect(() => {
        // Set loading to false after component mounts
        setIsLoading(false);
    }, []);

    // When still server-side or initially loading, show static version with correct size
    if (isLoading) {
        return <StaticDiscoBall size={size} imageProps={{ priority: true }} alt={alt} />;
    }

    return <DynamicDiscoBall {...props} />;
}