'use client';

import React, { useState } from 'react';
import Link from 'next/link';
import LoadingLogo from './loading_logo';
import { SxProps } from '@mui/material';
interface LinkWithLoadingProps {
    href: string;
    children: React.ReactNode;
    className?: string;
    loadingMessage?: string;
    style?: React.CSSProperties;
    sx?: SxProps;
    passHref?: boolean;
    target?: string;
    rel?: string;
    LoadingComponent?: React.ComponentType<{ message?: string }>;
    legacyBehavior?: boolean;
}

const LinkWithLoading: React.FC<LinkWithLoadingProps> = ({
    href,
    children,
    className,
    loadingMessage,
    style,
    passHref,
    target,
    rel,
    LoadingComponent,
    sx,
    legacyBehavior = false
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleClick = () => {
        if (!target || target === '_self') {
            setIsLoading(true);
        }
    };

    // Check if children is an <a> element
    const childrenIsAnchor = React.isValidElement(children) &&
        (typeof children.type === 'string' && children.type === 'a');

    // Use legacyBehavior if explicitly set or if children is an <a> tag
    const useLegacyBehavior = legacyBehavior || childrenIsAnchor;

    return (
        <>
            {useLegacyBehavior ? (
                <Link
                    href={href}
                    passHref={true}
                    legacyBehavior={true}
                >
                    {childrenIsAnchor ?
                        children :
                        <a
                            className={className}
                            onClick={handleClick}
                            style={{ ...style, ...(sx as React.CSSProperties) }}
                            target={target}
                            rel={rel}
                        >
                            {children}
                        </a>
                    }
                </Link>
            ) : (
                <Link
                    href={href}
                    className={className}
                    onClick={handleClick}
                    style={{ ...style, ...(sx as React.CSSProperties) }}
                    target={target}
                    rel={rel}
                >
                    {children}
                </Link>
            )}
            {isLoading && (
                LoadingComponent ?
                    <LoadingComponent message={loadingMessage} /> :
                    <LoadingLogo message={loadingMessage} />
            )}
        </>
    );
};

export default LinkWithLoading;
