import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Rating,
  Skeleton,
  Typography,
} from '@mui/material';

import ImageCarouselSwiper from './image_carousel_swiper';
import { Venue, DayOfWeek, DayAvailability, VenueBookedTimeSlot } from '../../../shared/models';
import Attributes from './attributes';
import LinkWithLoading from './link_with_loading';

export function HorizontalFeaturedCard({ venue, venueBookedTimeSlots, attributeIcons }: { venue: Venue, venueBookedTimeSlots: Record<string, VenueBookedTimeSlot[]>, attributeIcons: Record<string, string> }) {
  const getNextAvailabilities = (venue: Venue): string[] => {
    const today = new Date();
    const nextDays: string[] = [];

    for (let i = 0; i < 7 && nextDays.length < 4; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      const dayOfWeek = nextDate.toLocaleString('en-US', { weekday: 'long' }) as DayOfWeek;
      const dateString = nextDate.toDateString();
      const formattedDate = `${nextDate.getMonth() + 1}/${nextDate.getDate()}`;

      if (venue.Availability[dayOfWeek] && venue.Availability[dayOfWeek].IsOpen) {
        if (!venueBookedTimeSlots[dateString] || venueBookedTimeSlots[dateString].length === 0) {
          nextDays.push(formattedDate);
        }
      }
    }

    return nextDays;
  };

  return (
    <Grid item xs={12} sm={6} md={4} lg={4} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        sx={{
          width: '100%',
          maxWidth: { xs: '100%' },
          height: { xs: 450, sm: 500, lg: 500, xl: 560 },
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          color: 'white',
          borderRadius: '16px',
          boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
          overflow: 'hidden',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ height: '100%', position: 'relative', width: '100%' }}>
          <ImageCarouselSwiper
            images={
              venue.PhotoUrls
                ? venue.PhotoUrls.map((url) => ({
                  src: url,
                  link: `/venue/${venue.ID}`,
                }))
                : []
            }
          />
          {false && (
            <Box
              sx={{
                position: 'absolute',
                top: 16,
                right: 16,
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                color: 'white',
                padding: '4px 8px',
                borderRadius: '16px',
                fontFamily: 'Sans',
                fontSize: '0.75rem',
                zIndex: 1,
              }}
            >
              Exclusive Deal
            </Box>
          )}
        </Box>

        <LinkWithLoading href={`/venue/${venue.ID}`} className="w-full">
          <CardContent sx={{ p: 2, flexGrow: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box
              sx={{
                height: '100%',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                <Typography variant="h3" sx={{ color: 'white', fontFamily: 'Thick', fontSize: { xs: '1.5rem', xl: '1.9rem' } }}>
                  {venue.Name}
                </Typography>
                <Rating value={venue.Rating} readOnly precision={0.5} size="small" sx={{ color: 'rgba(255, 255, 255, 0.6)' }} />
              </Box>
              <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.6)', fontFamily: 'Sans', mb: 0.5, fontSize: { xs: '0.75rem', xl: '0.875rem' } }}>
                Available starting {getNextAvailabilities(venue)[0]}
              </Typography>
              <Attributes attributes={venue.Attributes} attributeIcons={attributeIcons} maxAttributes={3} muted={true} />
              {
                false && (
                  <Typography
                    fontFamily='Sans'
                    sx={{
                      color: '#FF385C',
                      fontWeight: 'bold',
                      mt: 0.5,
                      fontSize: { xs: '0.875rem', xl: '1rem' },
                    }}
                  >
                    Special offer available!
                  </Typography>
                )
              }
            </Box>
          </CardContent>
        </LinkWithLoading>
      </Card>
    </Grid>
  );
}

export function HorizontalFeaturedCardSkeleton() {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={3} sx={{ display: 'flex', justifyContent: 'center' }}>
      <Card sx={{
        width: '100%',
        maxWidth: { xs: '100%', sm: 400, xl: 500 },
        height: { xs: 450, sm: 500, lg: 500, xl: 560 },
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        color: 'white',
        borderRadius: '16px',
        boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.2)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={400}
          animation="wave"
          sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)' }}
        />
        <CardContent sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: { xs: 100, sm: 'auto', xl: 120 } }}> {/* Increased height for extra large screens */}
          <Box>
            <Skeleton
              variant="text"
              width="80%"
              height={48}
              animation="wave"
              sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 1 }}
            />
            <Skeleton
              variant="text"
              width="60%"
              height={24}
              animation="wave"
              sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', mb: 0.5 }}
            />
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}
