import React from 'react';
import { Box, Container, Grid, Typography, IconButton } from '@mui/material';
import Link from 'next/link';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkWithLoading from './link_with_loading';

const TikTokIcon = ({ color = "#FFFFFF" }) => {
    return (
        <svg
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="24px"
            height="24px"
        >
            <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
        </svg>
    );
};

const LargeFooter = () => {
    return (
        <Box
            component="footer"
            sx={{
                bgcolor: 'linear-gradient(to top, #2c2c54, rgba(44, 44, 84, 0.8))',
                color: '#fff',
                py: 8,
                borderTop: '1px solid rgba(224, 177, 241, 0.2)',
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" fontFamily="Thick" gutterBottom>
                            Support
                        </Typography>
                        <LinkWithLoading href="/contact" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Contact Us</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/terms" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Terms of Service</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/privacy" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Privacy Policy</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/venue_terms" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Venue Terms</Typography>
                        </LinkWithLoading>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" fontFamily="Thick" gutterBottom>
                            Discover
                        </Typography>
                        <LinkWithLoading href="/search" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Search Venues</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/events" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Viewed Events</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/blog" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Blog</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/list_your_venue" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>List Your Venue</Typography>
                        </LinkWithLoading>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" fontFamily="Thick" gutterBottom>
                            Account
                        </Typography>
                        <LinkWithLoading href="/login" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Login</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/profile" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Profile</Typography>
                        </LinkWithLoading>
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" fontFamily="Thick" gutterBottom>
                            Company
                        </Typography>
                        <LinkWithLoading href="/" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>Home</Typography>
                        </LinkWithLoading>
                        <LinkWithLoading href="/about" style={{ color: '#AAAAAA', textDecoration: 'none' }}>
                            <Typography sx={{ mb: 1 }}>About Us</Typography>
                        </LinkWithLoading>
                    </Grid>
                </Grid>

                <Box
                    sx={{
                        mt: 6,
                        pt: 3,
                        borderTop: '1px solid rgba(224, 177, 241, 0.2)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexWrap: 'wrap'
                    }}
                >
                    <Typography color="#AAAAAA" sx={{ mb: { xs: 2, md: 0 } }}>
                        © {new Date().getFullYear()} Get Litty, LLC. All rights reserved
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <IconButton
                            href="https://instagram.com/getlittyny"
                            target="_blank"
                            sx={{ color: '#E0B1F1' }}
                        >
                            <InstagramIcon />
                        </IconButton>
                        <IconButton
                            href="https://twitter.com/getlittynyc"
                            target="_blank"
                            sx={{ color: '#E0B1F1' }}
                        >
                            <TwitterIcon />
                        </IconButton>
                        <IconButton
                            href="https://tiktok.com/getlittynyc"
                            target="_blank"
                            sx={{ color: '#E0B1F1' }}
                        >
                            <TikTokIcon />
                        </IconButton>
                        <IconButton
                            href="https://linkedin.com/company/getlitty"
                            target="_blank"
                            sx={{ color: '#E0B1F1' }}
                        >
                            <LinkedInIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default LargeFooter;
