'use client';

import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, List, ListItem, ListItemText, IconButton, Divider } from '@mui/material';
import { MatchMakerSession, Venue } from '../../../shared/models';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { getBackendURL, calculateCheapestVenuePricing } from '../lib/util';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PeopleIcon from '@mui/icons-material/People';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const formatDate = (dateStr: string) => {
    const [year, month, day] = dateStr.split('-');
    return `${month}/${day}/${year}`;
};

const LoadingSpinner = () => (
    <Box
        sx={{
            display: 'inline-block',
            width: '20px',
            height: '20px',
            animation: 'spin 1s linear infinite',
            '@keyframes spin': {
                '0%': {
                    transform: 'rotate(0deg)',
                },
                '100%': {
                    transform: 'rotate(360deg)',
                },
            },
        }}
    >
        <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
        >
            <circle
                cx="12"
                cy="12"
                r="10"
                strokeDasharray="42"
                strokeDashoffset="12"
                stroke="#E0B1F1"
            />
        </svg>
    </Box>
);

interface InquireToVenueMatchmakerProps {
    venue: Venue;
}

const InquireToVenueMatchmaker: React.FC<InquireToVenueMatchmakerProps> = ({ venue }) => {
    const [open, setOpen] = useState(false);
    const [matchmakerSessions, setMatchmakerSessions] = useState<MatchMakerSession[]>([]);
    const isDevelopment = process.env.NODE_ENV === 'development';
    const [isLoading, setIsLoading] = useState(false);
    const [deletedSessionId, setDeletedSessionId] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Get all matchmaker sessions from localStorage
        const sessionsStr = localStorage.getItem('matchmaker_sessions');
        if (sessionsStr) {
            const sessions = JSON.parse(sessionsStr) as MatchMakerSession[];
            setMatchmakerSessions(sessions);
        }
        // Ensure loading is set to false after sessions are loaded
        setLoading(false);
    }, []);

    const handleInquire = async (session: MatchMakerSession) => {
        try {
            setIsLoading(true);
            if (!session.VenueIDsReachedOutTo.includes(venue.ID)) {
                // Prepare the request body
                const requestBody = {
                    matchMakerSessionId: session.ID,
                    venueIds: [venue.ID]
                };

                // Send the inquiry to the backend
                const response = await fetch(`${getBackendURL('sendMatchmakerInquiry')}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody)
                });

                if (!response.ok) {
                    throw new Error('Failed to send inquiry');
                }

                // If successful, update local state
                const updatedSession = {
                    ...session,
                    VenueIDsReachedOutTo: [...session.VenueIDsReachedOutTo, venue.ID]
                };

                // Update the session in the sessions array
                const updatedSessions = matchmakerSessions.map(s =>
                    s.ID === session.ID ? updatedSession : s
                );

                // Update localStorage
                localStorage.setItem('matchmaker_sessions', JSON.stringify(updatedSessions));
                localStorage.setItem('matchmaker_session', JSON.stringify(updatedSession));
                setMatchmakerSessions(updatedSessions);
            }
        } catch (error) {
            console.error('Error sending inquiry:', error);
            // You might want to add error handling UI here
            alert('Failed to send inquiry. Please try again.');
        } finally {
            setIsLoading(false);
            setOpen(false);
        }
    };

    const handleButtonClick = () => {
        if (matchmakerSessions.length === 1) {
            // If there's only one session, send inquiry directly
            handleInquire(matchmakerSessions[0]);
        } else {
            // If there are multiple sessions, open the modal
            setOpen(true);
        }
    };

    const clearMatchmakerSessions = () => {
        localStorage.removeItem('matchmaker_sessions');
        localStorage.removeItem('matchmaker_session');
        setMatchmakerSessions([]);
    };

    const deleteSession = (sessionId: string) => {
        // Mark the session as deleted first
        setDeletedSessionId(sessionId);

        // Wait for animation to complete before updating state
        setTimeout(() => {
            const updatedSessions = matchmakerSessions.filter(s => s.ID !== sessionId);
            setMatchmakerSessions(updatedSessions);
            localStorage.setItem('matchmaker_sessions', JSON.stringify(updatedSessions));

            // If we deleted the last session, close the modal after a brief delay
            if (updatedSessions.length === 0) {
                setTimeout(() => {
                    setOpen(false);
                }, 500);
            }

            // Reset the deleted session id
            setDeletedSessionId(null);
        }, 600);
    };

    // Don't render anything if there are no sessions
    if (!matchmakerSessions.length) {
        return isDevelopment ? (
            <Box sx={{
                mt: 2
            }}>
                <Button
                    variant="outlined"
                    startIcon={<DeleteIcon />}
                    onClick={clearMatchmakerSessions}
                    sx={{
                        borderColor: '#5A4A84',
                        color: '#5A4A84',
                        width: '100%',
                        borderRadius: '8px',
                        py: 1.5,
                        textTransform: 'none',
                        fontWeight: 500,
                        '&:hover': {
                            borderColor: '#4A3A74',
                            backgroundColor: 'rgba(90, 74, 132, 0.1)',
                        }
                    }}
                >
                    Clear Matchmaker Sessions
                </Button>
            </Box >
        ) : null;
    }

    const isSessionSent = matchmakerSessions[0]?.VenueIDsReachedOutTo.includes(venue.ID);

    return (
        <Box sx={{ mt: 2, width: '100%' }}>
            <Button
                variant="contained"
                fullWidth
                onClick={handleButtonClick}
                disabled={isSessionSent || isLoading}
                startIcon={isSessionSent ? <CheckCircleIcon /> : <SendIcon />}
                sx={{
                    backgroundColor: '#5A4A84',
                    color: 'white',
                    fontFamily: 'Sans',
                    py: 1.5,
                    fontSize: '1rem',
                    fontWeight: 500,
                    borderRadius: '12px',
                    textTransform: 'none',
                    boxShadow: '0 4px 12px rgba(90, 74, 132, 0.3)',
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                        backgroundColor: '#6B5B95',
                        transform: 'translateY(-2px)',
                        boxShadow: '0 6px 16px rgba(90, 74, 132, 0.4)',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: isSessionSent ? '#E8F5E9' : 'rgba(90, 74, 132, 0.3)',
                        color: isSessionSent ? '#2E7D32' : 'rgba(255, 255, 255, 0.3)',
                        animation: 'none',
                        fontWeight: isSessionSent ? 600 : 400
                    }
                }}
            >
                {isLoading ? (
                    <LoadingSpinner />
                ) : isSessionSent ? (
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: '#4CAF50'
                    }}>
                        <span>Successfully Sent Inquiry for {formatDate(matchmakerSessions[0].InitialDate)}</span>
                    </Box>
                ) : (
                    matchmakerSessions.length === 1
                        ? `Send Inquiry for ${formatDate(matchmakerSessions[0].InitialDate)}`
                        : 'Send Inquiry for Previously Inquired Date'
                )}
            </Button>

            {isDevelopment && (
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        variant="outlined"
                        onClick={clearMatchmakerSessions}
                        startIcon={<DeleteIcon />}
                        sx={{
                            borderColor: '#5A4A84',
                            color: '#5A4A84',
                            borderRadius: '8px',
                            textTransform: 'none',
                            '&:hover': {
                                borderColor: '#4A3A74',
                                backgroundColor: 'rgba(90, 74, 132, 0.1)',
                            }
                        }}
                    >
                        Clear Matchmaker Sessions
                    </Button>
                </Box>
            )}

            {/* Only render modal if there are multiple sessions */}
            {matchmakerSessions.length > 1 && (
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: { xs: 1, sm: 2 }
                    }}
                >
                    <Box sx={{
                        backgroundColor: '#1a1a1a',
                        borderRadius: 3,
                        p: { xs: 3, sm: 4 },
                        maxWidth: 600,
                        width: '100%',
                        maxHeight: '90vh',
                        overflow: 'auto',
                        position: 'relative',
                        boxShadow: '0 8px 32px rgba(0, 0, 0, 0.4)',
                        color: 'white',
                        m: { xs: 1, sm: 2 }
                    }}>
                        <IconButton
                            onClick={() => setOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: 12,
                                top: 12,
                                color: 'white',
                                bgcolor: 'rgba(255, 255, 255, 0.1)',
                                '&:hover': {
                                    bgcolor: 'rgba(255, 255, 255, 0.2)',
                                }
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Typography variant="h5" fontFamily="Thick" sx={{ mb: 4, pr: 4 }}>
                            Choose Event Details to Send
                        </Typography>

                        <List sx={{ pt: 1 }}>
                            {matchmakerSessions.map((session, index) => (
                                <React.Fragment key={session.ID}>
                                    <ListItem
                                        sx={{
                                            flexDirection: 'column',
                                            alignItems: 'stretch',
                                            p: { xs: 3, sm: 4 },
                                            borderRadius: '16px',
                                            mb: 2.5,
                                            position: 'relative',
                                            '&:hover': {
                                                transform: 'translateY(-2px)',
                                                boxShadow: '0 4px 20px rgba(224, 177, 241, 0.1)',
                                                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                                            },
                                            transform: deletedSessionId === session.ID ? 'scale(0.95) translateX(100%)' : 'none',
                                            transition: 'all 0.3s ease',
                                            opacity: deletedSessionId === session.ID ? 0 : 1,
                                            backgroundColor: 'rgba(255, 255, 255, 0.05)',
                                        }}
                                    >
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 3 }}>
                                            <Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                                    <CalendarTodayIcon sx={{ color: '#E0B1F1', mr: 1.5, fontSize: '1.2rem' }} />
                                                    <Typography variant="subtitle1" fontWeight="bold">
                                                        {formatDate(session.InitialDate)}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <AccessTimeIcon sx={{ color: '#E0B1F1', mr: 1.5, fontSize: '1.2rem' }} />
                                                    <Typography variant="body2" color="text.secondary" sx={{ color: 'rgba(255, 255, 255, 0.7)' }}>
                                                        {session.InitialStartTime} - {session.InitialEndTime}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {(() => {
                                                let priceDisplay = null;

                                                if (venue && !loading) {
                                                    const date = new Date(session.InitialDate);

                                                    try {
                                                        const price = calculateCheapestVenuePricing(
                                                            venue,
                                                            session.GuestCount,
                                                            session.InitialStartTime,
                                                            session.InitialEndTime,
                                                            date
                                                        );
                                                        priceDisplay = (
                                                            <Box sx={{
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                alignItems: 'flex-end',
                                                                backgroundColor: 'rgba(224, 177, 241, 0.1)',
                                                                padding: '10px 14px',
                                                                borderRadius: '12px'
                                                            }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <MonetizationOnIcon sx={{ color: '#E0B1F1', mr: 1, fontSize: '1.2rem' }} />
                                                                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.95)', fontSize: '1.1rem', fontWeight: 700 }}>
                                                                        ${price.price.toLocaleString()}
                                                                    </Typography>
                                                                </Box>
                                                                <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', fontSize: '0.8rem', mt: 0.5 }}>
                                                                    Estimated price
                                                                </Typography>
                                                            </Box>
                                                        );
                                                    } catch (error) {
                                                        console.error('Error calculating price:', error);
                                                    }
                                                }

                                                return priceDisplay;
                                            })()}
                                        </Box>

                                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                                            <PeopleIcon sx={{ color: '#E0B1F1', mr: 1.5, fontSize: '1.2rem' }} />
                                            <Typography variant="body2">
                                                <strong>{session.GuestCount}</strong> Guests
                                            </Typography>
                                        </Box>

                                        <IconButton
                                            onClick={() => deleteSession(session.ID)}
                                            sx={{
                                                position: 'absolute',
                                                right: 12,
                                                top: 12,
                                                color: 'rgba(255, 255, 255, 0.5)',
                                                bgcolor: 'rgba(255, 255, 255, 0.05)',
                                                '&:hover': {
                                                    color: '#ff4444',
                                                    backgroundColor: 'rgba(255, 68, 68, 0.1)',
                                                },
                                            }}
                                        >
                                            <DeleteOutlineIcon />
                                        </IconButton>

                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: { xs: 'column', sm: 'row' },
                                            justifyContent: 'space-between',
                                            alignItems: { xs: 'stretch', sm: 'flex-start' },
                                            width: '100%',
                                            gap: { xs: 2, sm: 0 },
                                            mt: 1
                                        }}>
                                            <Button
                                                variant="contained"
                                                onClick={() => handleInquire(session)}
                                                disabled={session.VenueIDsReachedOutTo.includes(venue.ID) || isLoading}
                                                fullWidth
                                                startIcon={session.VenueIDsReachedOutTo.includes(venue.ID) ? <CheckCircleIcon /> : <SendIcon />}
                                                sx={{
                                                    backgroundColor: '#5A4A84',
                                                    px: { xs: 2, sm: 4 },
                                                    py: 1.5,
                                                    borderRadius: '12px',
                                                    textTransform: 'none',
                                                    fontWeight: 500,
                                                    width: { xs: '100%', sm: 'auto' },
                                                    '&:hover': {
                                                        backgroundColor: '#4A3A74',
                                                        transform: 'translateY(-2px)',
                                                        boxShadow: '0 4px 12px rgba(90, 74, 132, 0.3)',
                                                    },
                                                    '&.Mui-disabled': {
                                                        backgroundColor: session.VenueIDsReachedOutTo.includes(venue.ID) ?
                                                            '#E8F5E9' : 'rgba(90, 74, 132, 0.3)',
                                                        color: session.VenueIDsReachedOutTo.includes(venue.ID) ?
                                                            '#2E7D32' : 'rgba(255, 255, 255, 0.3)',
                                                    },
                                                    transition: 'all 0.2s ease'
                                                }}
                                            >
                                                {isLoading ? (
                                                    <LoadingSpinner />
                                                ) : session.VenueIDsReachedOutTo.includes(venue.ID) ? (
                                                    'Inquiry Sent'
                                                ) : (
                                                    'Send Inquiry'
                                                )}
                                            </Button>
                                        </Box>
                                    </ListItem>
                                    {index < matchmakerSessions.length - 1 && (
                                        <Divider sx={{ backgroundColor: '#333333', my: 2.5 }} />
                                    )}
                                </React.Fragment>
                            ))}
                        </List>
                    </Box>
                </Modal>
            )}
        </Box>
    );
};

export default InquireToVenueMatchmaker;
