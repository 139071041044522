'use client';

import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import BookIcon from '@mui/icons-material/Book';
import InfoIcon from '@mui/icons-material/Info';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import CelebrationIcon from '@mui/icons-material/Celebration';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import ContactMailRounded from '@mui/icons-material/ContactMailRounded';
import Link from 'next/link';

const HamburgerNavbarMenu = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        { icon: <CelebrationIcon />, text: 'Viewed Events', href: '/events' },
        { icon: <BookIcon />, text: 'Blog', href: '/blog' },
        { icon: <InfoIcon />, text: 'About', href: '/about' },
        { icon: <AccountCircleOutlined />, text: 'Venue Owner Profile', href: '/profile' },
        { icon: <ContactMailRounded />, text: 'Contact', href: '/contact' },
    ];

    return (
        <Box>
            <IconButton
                color="inherit"
                sx={{
                    ml: 1,
                    color: 'lightgray',
                    transition: 'color 0.3s',
                    '&:hover': { color: 'white' },

                }}
                aria-label="Menu"
                onClick={handleMenuClick}
            >
                <MenuIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    elevation: 3,
                    sx: {
                        mt: 1.5,
                        minWidth: 200,
                        borderRadius: 2,
                        '& .MuiList-root': {
                            py: 1,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {menuItems.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={handleMenuClose}
                        component={Link}
                        href={item.href}
                        sx={{
                            py: 1.5,
                            px: 2.5,
                            '&:hover': { backgroundColor: 'action.hover' },
                        }}
                    >
                        {React.cloneElement(item.icon, { sx: { mr: 2, color: 'text.secondary' } })}
                        {item.text}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
};

export default HamburgerNavbarMenu;
