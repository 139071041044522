'use client';

import React, { Suspense, lazy } from 'react';
import { CircularProgress } from '@mui/material';
import dynamic from 'next/dynamic';

// Lazy load the date picker components
const LazyLocalizationProvider = dynamic(
    () => import('@mui/x-date-pickers').then((mod) => mod.LocalizationProvider),
    { ssr: false }
);

const LazyDatePicker = dynamic(
    () => import('@mui/x-date-pickers').then((mod) => mod.DatePicker),
    { ssr: false }
);

const LazyMobileDatePicker = dynamic(
    () => import('@mui/x-date-pickers').then((mod) => mod.MobileDatePicker),
    { ssr: false }
);

const LazyDateTimePicker = dynamic(
    () => import('@mui/x-date-pickers').then((mod) => mod.DateTimePicker),
    { ssr: false }
);

const LazyTimePicker = dynamic(
    () => import('@mui/x-date-pickers').then((mod) => mod.TimePicker),
    { ssr: false }
);

// For adapters, we need a different approach since they're classes, not components
// Create adapter loaders that will dynamically import the adapter when needed
let dayjsAdapterCache: any = null;
let dateFnsAdapterCache: any = null;

export const loadAdapterDayjs = async () => {
    if (!dayjsAdapterCache) {
        const { AdapterDayjs } = await import('@mui/x-date-pickers/AdapterDayjs');
        dayjsAdapterCache = AdapterDayjs;
    }
    return dayjsAdapterCache;
};

export const loadAdapterDateFns = async () => {
    if (!dateFnsAdapterCache) {
        const { AdapterDateFns } = await import('@mui/x-date-pickers/AdapterDateFns');
        dateFnsAdapterCache = AdapterDateFns;
    }
    return dateFnsAdapterCache;
};

// Loading spinner component
const DatePickerLoading = () => (
    <div className="flex justify-center items-center p-4">
        <CircularProgress size={24} />
    </div>
);

// Exported components with Suspense
export const DatePicker = (props: any) => (
    <Suspense fallback={<DatePickerLoading />}>
        <LazyDatePicker {...props} />
    </Suspense>
);

export const MobileDatePicker = (props: any) => (
    <Suspense fallback={<DatePickerLoading />}>
        <LazyMobileDatePicker {...props} />
    </Suspense>
);

export const DateTimePicker = (props: any) => (
    <Suspense fallback={<DatePickerLoading />}>
        <LazyDateTimePicker {...props} />
    </Suspense>
);

export const TimePicker = (props: any) => (
    <Suspense fallback={<DatePickerLoading />}>
        <LazyTimePicker {...props} />
    </Suspense>
);

export const LocalizationProvider = (props: any) => (
    <Suspense fallback={<DatePickerLoading />}>
        <LazyLocalizationProvider {...props} />
    </Suspense>
); 