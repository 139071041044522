'use client';

import React, { useState, useCallback, useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { useRouter, usePathname } from 'next/navigation';
import { IconButton } from '@mui/material';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import ArrowBackIosNewRounded from '@mui/icons-material/ArrowBackIosNewRounded';
import { nanoid } from 'nanoid';

// Import only necessary Swiper styles
import 'swiper/css/bundle';
import LoadingLogo from './loading_logo';
import OptimizedImage from './OptimizedImage';

interface ImageCarouselProps {
    images: { src: string; link?: string, key?: string, alt?: string }[];
}

const CustomNavButton = React.memo(({ direction, onClick }: { direction: 'prev' | 'next'; onClick: () => void }) => (
    <div
        className="absolute top-1/2 transform -translate-y-1/2 z-50"
        style={{ [direction === 'prev' ? 'left' : 'right']: '10px' }}
    >
        <IconButton
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onClick();
            }}
            sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                color: 'black',
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' },
            }}
        >
            {direction === 'prev' ? <ArrowBackIosNewRounded /> : <ArrowForwardIosRounded />}
        </IconButton>
    </div>
));

CustomNavButton.displayName = 'CustomNavButton';

const ImageCarouselSwiper: React.FC<ImageCarouselProps> = ({ images }) => {

    const [swiper, setSwiper] = useState<any>(null);
    const router = useRouter();
    const pathname = usePathname();
    const [isSwiping, setIsSwiping] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Add new state for tracking visible slides
    const [visibleSlides, setVisibleSlides] = useState<Set<number>>(new Set([0, 1]));

    // Dedupe images and use 800x800 version
    const processedImages = useMemo(() => {
        const imageMap = images.reduce((acc, img, index) => {
            const baseUrl = img.src.split('?')[0].replace(/_[0-9]+x[0-9]+/, '');

            if (!acc[baseUrl]) {
                acc[baseUrl] = {
                    src: img.src, // Use source URL directly, no optimization function
                    link: img.link,
                    alt: img.alt,
                    uniqueId: `slide-${index}-${nanoid()}`
                };
            }

            if (img.src.includes('_800x800')) {
                acc[baseUrl] = {
                    ...acc[baseUrl],
                    src: img.src, // Use source URL directly, no optimization function
                    link: img.link,
                    alt: img.alt
                };
            }

            return acc;
        }, {} as Record<string, { src: string, link?: string, alt?: string, uniqueId: string }>);

        const result = Object.values(imageMap);
        return result;
    }, [images]);

    const handleClick = useCallback((e: React.MouseEvent, link?: string) => {
        // Check if click originated from navigation buttons
        const target = e.target as HTMLElement;
        if (target.closest('.MuiIconButton-root')) {
            return;
        }

        if (link && !isSwiping) {
            setIsLoading(true);
            if (pathname === '/') {
                router.push(link);
            } else {
                window.open(link, '_blank', 'noopener,noreferrer');
                setIsLoading(false); // Reset loading immediately for external links
            }
        }
    }, [isSwiping, pathname, router]);

    const swiperParams = useMemo(() => ({
        modules: [Navigation, Pagination, Autoplay],
        spaceBetween: 0,
        slidesPerView: 1,
        loop: true,
        pagination: {
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
        },
        onSwiper: setSwiper,
        onSliderMove: () => setIsSwiping(true),
        onTouchEnd: () => setTimeout(() => setIsSwiping(false), 300),
        // Add slide change handler
        onSlideChange: (swiper: any) => {
            const activeIndex = swiper.realIndex;
            const nextIndex = (activeIndex + 1) % processedImages.length;
            const prevIndex = (activeIndex - 1 + processedImages.length) % processedImages.length;
            setVisibleSlides(new Set([activeIndex, nextIndex, prevIndex]));
        },
        className: "h-full",
    }), [processedImages.length]);

    const handleNavigation = useCallback((direction: 'prev' | 'next') => {
        if (direction === 'prev') {
            swiper?.slidePrev();
        } else {
            swiper?.slideNext();
        }
    }, [swiper]);

    return (
        <>
            {isLoading && <LoadingLogo />}
            <div className="relative h-full group">
                <Swiper {...swiperParams}>
                    {processedImages.map((image, index) => (
                        <SwiperSlide
                            key={image.uniqueId}
                        >
                            <div
                                onClick={(e) => handleClick(e, image.link)}
                                className="cursor-pointer relative w-full h-full"
                            >
                                <OptimizedImage
                                    src={image.src}
                                    alt={image.alt || `Carousel item ${index + 1}`}
                                    fill
                                    sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                                    priority={index === 0}
                                    style={{ objectFit: 'cover', objectPosition: 'center' }}
                                    lazy={index !== 0}
                                    quality={75}
                                    className="transition-opacity duration-300"
                                    unoptimized={false}
                                    placeholder="empty"
                                />
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-300 hidden md:block pointer-events-none">
                    <div className="relative w-full h-full pointer-events-auto">
                        <CustomNavButton direction="prev" onClick={() => handleNavigation('prev')} />
                        <CustomNavButton direction="next" onClick={() => handleNavigation('next')} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default React.memo(ImageCarouselSwiper);
